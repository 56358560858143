import React, { useState, useEffect } from "react";
import data from "../../../data";
import "../Resources/resources.scss";
import Nestedaccordionwithtabs from "../../NestedAccordionWithTabs/Nestedaccordionwithtabs";
import { useLocation } from "react-router-dom";

const Resources = (props, tab, setTab) => {
  const [id, setId] = useState("dcm");
  const params = useLocation();
  const [animationClass, setAnimationClass] = useState("fade");
  useEffect(() => {
    if (animationClass === "fade") {
      setAnimationClass("fade2");
    } else {
      setAnimationClass("fade");
    }
  }, [params.pathname]);
  return (
    <React.Fragment>
      <div className="resource-main">
        <div className="resource-wrapper">
          <div>
            {data
              ?.filter((item) => item.title === props.props.title)
              .map((tabs, index) => {
                return (
                  <div
                    className={`tabs-main ${
                      animationClass === "fade" ? "fade2" : "fade"
                    }`}
                    key={index}
                  >
                    <div className="tabs-wrapper">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {tabs?.resources[0]?.tabs[0]?.tab?.map(
                          (innerdata, i) => {
                            // console.log("innerdat", innerdata);
                            return (
                              <li
                                className="nav-item"
                                role="presentation"
                                key={i}
                              >
                                <button
                                  className={
                                    innerdata.id === id
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  id={`${innerdata.id}-tab`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#${innerdata.id}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`${innerdata.id}`}
                                  aria-selected="true"
                                  onClick={() => setId(`${innerdata.id}`)}
                                >
                                  {innerdata.subtitle}
                                </button>
                              </li>
                            );
                          }
                        )}
                      </ul>
                      {tabs?.resources[0]?.tabs[0]?.tab?.map((innerdata, i) => {
                        return (
                          <div className="tab-content" key={i}>
                            <div
                              className={
                                innerdata.id === id
                                  ? "tab-pane active"
                                  : "tab-pane"
                              }
                              id={`${innerdata.id}`}
                              role="tabpanel"
                              aria-labelledby={`${innerdata.id}-tab`}
                            >
                              <Nestedaccordionwithtabs nestedData={innerdata} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Resources;
