import { numberWithCommasAndDecimal } from "../../../../../src/Utils/helper";
import React, { useEffect, useState } from "react";
import TradingHours, { getMarketStatus, TradingHours2 } from "../MarketHours";
import { ArrowDown, ArrowUp, Moon, Sun } from "lucide-react";
import { format } from "date-fns";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../src/components/ui/popover";
import {
  getDeviceType,
  isMarketOpen,
  isUSHoliday,
  isWeekend,
} from "../../../../../src/lib/utils";

const now = new Date();

const MarketStatus = ({
  isIndex,
  liveChanges,
  fundDetails,
  livePrice,
  lineChartData2,
  portSum,
  prevlivePrice,
  lineChart,
}) => {
  const [priceChangeClass, setpriceChangeClass] = useState("text-white");
  const lastUnitPrice =
    lineChartData2[lineChartData2?.length - 1]?.unitPrice ||
    fundDetails?.unitPrice;
  const formattedPrice =
    numberWithCommasAndDecimal(livePrice || lastUnitPrice, 4) || "--";
  const currencyCode = portSum?.currency?.code;
  const change = liveChanges?.change;
  const percentageChange =
    liveChanges?.percentageChange ||
    fundDetails?.unitPriceOneDayChangePercentage;

  useEffect(() => {
    if (portSum?.portfolioName !== "Global Multi Sector Index") {
      setpriceChangeClass("text-white");
    } else {
      livePrice > prevlivePrice && isIndex
        ? setpriceChangeClass("text-green-500 animate-pulse")
        : setpriceChangeClass("text-red-500 animate-pulse");

      setTimeout(() => {
        setpriceChangeClass("text-white");
      }, 1000);
    }
  }, [liveChanges?.change]);

  return (
    <div className="flex items-center mt-4 w-full justify-between fade-in-5">
      <div className="flex flex-col justify-start items-start">
        {lastUnitPrice ? (
          <div className="flex flex-row justify-end items-end">
            <PriceDisplay
              price={formattedPrice}
              currencyCode={currencyCode}
              priceChangeClass={priceChangeClass}
              change={change}
              percentageChange={percentageChange}
              isIndex={isIndex}
              liveChanges={liveChanges}
              fundDetails={fundDetails}
              portSum={portSum}
            />
          </div>
        ) : (
          <div className="animate-pulse rounded-lg w-[100px] h-[25px] bg-zinc-900 mb-1" />
        )}
        <UpdateInfo
          isIndex={isIndex}
          date={lineChart[lineChart?.length - 1]}
          portSum={portSum}
        />
      </div>
      <MarketStatusPopover isIndex={isIndex} portSum={portSum} />
    </div>
  );
};

const PriceDisplay = ({
  price,
  currencyCode,
  priceChangeClass,
  change,
  isIndex,
  liveChanges,
  fundDetails,
  portSum,
}) => (
  <>
    <text
      className={`text-3xl font-bold mr-1 ${priceChangeClass} transition-colors fade-in-5`}
    >
      {price}
    </text>
    <text className="text-base text-gray-400 mr-3">{currencyCode}</text>

    {change !== 0 && (
      <div className="h-[20px]">
        {change < 0 ? (
          <ArrowDown size={18} className={` ${"text-red-400"} mr-0.5`} />
        ) : (
          <ArrowUp size={18} className={` ${"text-green-400"} mr-0.5`} />
        )}
      </div>
    )}
    {change !== 0 && (
      <text
        className={`min-w-[35px] text-base ${
          change < 0 ? "text-red-400" : "text-green-400"
        } mr-2 fade-in-5`}
      >
        {isIndex
          ? Math.abs(Number(liveChanges?.change)).toFixed(4)
          : numberWithCommasAndDecimal(
              Math.abs(fundDetails?.unitPriceOneDayChange)
            )}
      </text>
    )}
    {change !== 0 && (
      <text
        className={`min-w-[35px] text-base ${
          change < 0 ? "text-red-400" : "text-green-400"
        } mr-3 fade-in-5`}
      >
        {isIndex
          ? Math.abs(Number(liveChanges?.percentageChange)).toFixed(2)
          : numberWithCommasAndDecimal(
              Math.abs(fundDetails?.unitPriceOneDayChangePercentage)
            )}
        %
      </text>
    )}
    {change === 0 && (
      <text className={`min-w-[35px] text-base text-zinc-600 mr-3 fade-in-5`}>
        No Change
      </text>
    )}
    {/* {getMarketStatus(isIndex)?.stat === "Market Closed" &&
      (change < 0 ? (
        <ArrowDown size={15} className="text-base mb-1 text-red-400 mr-2" />
      ) : (
        <ArrowUp size={15} className="text-base mb-1 text-green-400 mr-2" />
      ))} */}
  </>
);

const UpdateInfo = ({ isIndex, date, portSum }) => {
  let datenew = date?.label?.split("-");
  if (!date) {
    return (
      <div className="animate-pulse rounded-lg w-[150px] h-[25px] bg-zinc-900 mb-1 fade-in-5 fade-out-5" />
    );
  }
  return (
    <div className="flex flex-row justify-end items-end text-sm text-gray-500 fade-in-5">
      {isIndex
        ? `${format(new Date(), "MMM dd, yyyy")} `
        : format(
            date?.label
              ? new Date(`${datenew[2]}-${datenew[1]}-${datenew[0]}`)
              : new Date(),
            "MMM dd, yyyy"
          )}
      {isIndex && portSum?.portfolioName === "Global Multi Sector Index"
        ? format(new Date().setHours(new Date().getHours() - 4), "hh:mm")
        : ""}
      {isIndex && portSum?.portfolioName === "Global Multi Sector Index"
        ? " EDT"
        : ""}
    </div>
  );
};

export const MarketStatusPopover = ({ isIndex, portSum }) => {
  return (
    <Popover style={{ borderWidth: 0 }}>
      <PopoverTrigger className="w-fit bg-transparent border-none bg-blue-950 fade-in-5">
        <div
          className={`md:w-[150px] cursor-pointer flex flex-row justify-center items-center px-1.5 md:px-3 py-1.5 rounded-full ${
            isIndex
              ? getMarketStatus(isIndex)?.stat === "Market Open"
                ? "bg-[#4ade8030] hover:bg-[#4ade8060]"
                : getMarketStatus(isIndex)?.stat === "Post-Market"
                ? "bg-[#17255480] hover:bg-[#17255490]"
                : "bg-[#ff000030] hover:bg-[#ff000060]"
              : isMarketOpen(isIndex)
              ? "bg-[#4ade8030] hover:bg-[#4ade8060]"
              : "bg-[#17255480] hover:bg-[#17255490]"
          }`}
        >
          {(
            isIndex
              ? getMarketStatus(isIndex)?.stat === "Market Open"
              : isMarketOpen(isIndex)
          ) ? (
            <Sun size={15} className="text-green-400" />
          ) : (
            <Moon
              size={15}
              className={`${
                isIndex
                  ? getMarketStatus(isIndex)?.stat === "Post-Market"
                    ? "text-blue-500"
                    : "text-red-500"
                  : "text-red-500"
              }`}
            />
          )}
          <div
            className={`${
              getDeviceType() == "mobile" && "hidden"
            } sm:flex ml-2 text-sm ${
              isIndex
                ? getMarketStatus(isIndex)?.stat === "Post-Market"
                  ? "text-blue-500"
                  : getMarketStatus(isIndex)?.stat === "Pre-Market" ||
                    getMarketStatus(isIndex)?.stat === "Market Closed"
                  ? "text-red-500"
                  : "text-green-500"
                : isMarketOpen(isIndex)
                ? "text-green-500"
                : "text-red-500"
            } w-fit`}
          >
            {isMarketOpen(isIndex)
              ? "Market Open"
              : isIndex
              ? getMarketStatus(isIndex)?.stat
              : "Market Closed"}
          </div>
        </div>
      </PopoverTrigger>
      {(portSum?.portfolioName === "Global Multi Sector Index" ||
        portSum?.portfolioName === "Ghana Equity Total Return Index") &&
        !(isWeekend(now) || isUSHoliday(now)) && (
          <PopoverContent
            className="w-[80vw] md:w-[30vw] lg:w-[20vw] h-fit bg-black mr-8 md:mr-10 mt-1 border border-red-500"
            style={{ borderColor: "rgba(113 113 122)" }}
          >
            {portSum?.portfolioName === "Global Multi Sector Index" && (
              <TradingHours isIndex={isIndex} />
            )}
            {portSum?.portfolioName === "Ghana Equity Total Return Index" && (
              <TradingHours2 isIndex={isIndex} />
            )}
          </PopoverContent>
        )}
    </Popover>
  );
};

export default MarketStatus;
