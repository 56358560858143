// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-sub-menu {
  overflow-x: auto;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrapper-sub-menu::-webkit-scrollbar {
  width: 5px;
  border-radius: 21px;
  height: 3px;
}
.wrapper-sub-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.wrapper-sub-menu::-webkit-scrollbar-thumb {
  background: #be9a2b;
}
.wrapper-sub-menu::-webkit-scrollbar-thumb:hover {
  background: #be9a2b;
}
.wrapper-sub-menu .main-sub-menu-tab {
  width: 100%;
  font-family: Outfit-Regular, sans-serif;
  display: flex;
  justify-content: flex-start;
}
.wrapper-sub-menu .main-sub-menu-tab .sub-tab {
  cursor: pointer;
  padding: 10px;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 10px;
}
.wrapper-sub-menu .main-sub-menu-tab .sub-tab p {
  color: #58595b;
  font-weight: 600;
  font-size: 14px;
}
.wrapper-sub-menu .main-sub-menu-tab .active p {
  color: #ffffff;
}

table {
  border: none;
}
table thead {
  margin-bottom: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/MutualFunds/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EAOA,UAAA;EAKA,WAAA;EAKA,oBAAA;AAbF;AAHE;EACE,UAAA;EACA,mBAAA;EACA,WAAA;AAKJ;AADE;EACE,mBAAA;AAGJ;AACE;EACE,mBAAA;AACJ;AAGE;EACE,mBAAA;AADJ;AAIE;EAOE,WAAA;EACA,uCAAA;EACA,aAAA;EACA,2BAAA;AARJ;AAWI;EACE,eAAA;EACA,aAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;AATN;AAUM;EACE,cAAA;EACA,gBAAA;EACA,eAAA;AARR;AAYM;EACE,cAAA;AAVR;;AAeA;EACE,YAAA;AAZF;AAaE;EACE,8BAAA;AAXJ","sourcesContent":[".wrapper-sub-menu {\n  overflow-x: auto;\n  &::-webkit-scrollbar {\n    width: 5px;\n    border-radius: 21px;\n    height: 3px;\n  }\n\n  /* Track */\n  &::-webkit-scrollbar-track {\n    background: #f1f1f1;\n  }\n\n  /* Handle */\n  &::-webkit-scrollbar-thumb {\n    background: #be9a2b;\n  }\n\n  /* Handle on hover */\n  &::-webkit-scrollbar-thumb:hover {\n    background: #be9a2b;\n  }\n\n  .main-sub-menu-tab {\n    // @media screen and (max-width: 900px) {\n    //   width: 900px;\n    // }\n    // @media screen and (min-width: 500px) {\n    //   width: \"100%\";\n    // }\n    width: 100%;\n    font-family: Outfit-Regular, sans-serif;\n    display: flex;\n    justify-content: flex-start;\n    // border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784);\n    // border-top: 0.5px solid rgba(88, 89, 91, 0.4901960784);\n    .sub-tab {\n      cursor: pointer;\n      padding: 10px;\n      width: fit-content;\n      margin-right: 10px;\n      p {\n        color: #58595b;\n        font-weight: 600;\n        font-size: 14px;\n      }\n    }\n    .active {\n      p {\n        color: #ffffff;\n      }\n    }\n  }\n}\ntable {\n  border: none;\n  thead {\n    margin-bottom: 20px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
