"use client";

import { Api, DOMAIN } from "../../../../src/Api";
import React, { useEffect, useMemo, useState } from "react";
import SparklineComp, { SparklineCompLocal } from "./SparkLine";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import { useLocation } from "react-router-dom";
import { INDX_KEYS, KEYSx } from "../../IFunds/IFunds";
import {
  getDeviceType,
  isUSHoliday,
  isWeekend,
  percentageDifference,
} from "../../../../src/lib/utils";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import OTPInput from "otp-input-react";
import { Loader } from "lucide-react";
import { processAssetTypeDetails } from "./ui/Overview";
// import Blur from "../../../Assets/Icon/blur.png";

const now = new Date();

export function HoldingsComp({ nav, isIndex, liveTickerPrices, portSum }) {
  // console.log(nav);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const { pathname } = useLocation();

  let query = useQuery();
  let menu = Number(query.get("menu"));

  let KEYS = pathname == "/fund" ? KEYSx : INDX_KEYS;

  const [data, setData] = useState(null);
  const [showMore, setshowMore] = useState(false);

  // Memoize the fetch function to avoid re-creating it on every render
  const fetchData = useMemo(() => {
    return () => {
      if (nav && !data) {
        Api.getCISData(KEYS[menu - 1], parseFloat(nav))
          .then((response) => {
            if (response?.status) {
              setData(response?.data);
              // Save data to sessionStorage
              sessionStorage.setItem(
                "cisData" + KEYS[menu - 1],
                JSON.stringify(response?.data)
              );

              const AllocationData = processAssetTypeDetails(
                response?.data?.assetClass?.ASSET_TYPE_DETAIL
              );

              sessionStorage.setItem(
                "pieChartData" + KEYS[menu - 1],
                JSON.stringify(AllocationData)
              );
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
  }, [portSum, menu, data, nav]);

  useEffect(() => {
    const savedData = sessionStorage.getItem("cisData" + KEYS[menu - 1]);
    if (savedData) {
      setData(JSON.parse(savedData));
    } else {
      fetchData();
    }
  }, []);

  const [isNewUser, setIsNewUser] = useState(true);

  useEffect(() => {
    let result = localStorage.getItem("isUser");
    if (result) {
      setIsNewUser(false);
    }
  }, []);

  if (data == null) {
    return (
      <div className=" w-full mt-4 animate-pulse">
        <div className="h-[20px] mb-4 w-[180px] p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
        <div className=" w-full  grid grid-cols-3 gap-4">
          <div className="h-[100px]  w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
          <div className="h-[100px]  w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
          <div className="h-[100px]  w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
        </div>

        <div className="h-[300px] mt-4 w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
      </div>
    );
  }

  if (isNewUser) {
    return (
      <div className="fade w-full">
        <VerificationForm
          reload={() => {
            setIsNewUser(!isNewUser);
          }}
        />
      </div>
    );
  }

  return (
    <div className="fade w-full relative">
      <div className="flex items-center w-full justify-between h-[1px] bg-[#58595b7d]" />
      <div className="flex flex-col justify-start items-start min-h-fit mt-4 w-full rounded-xl ">
        <div className="flex items-center justify-center gap-2">
          <text className="text-xl font-bold">Top 10 Holdings</text>
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <InfoCircledIcon className="hover:text-yellow-500 cursor-pointer" />
              </TooltipTrigger>
              <TooltipContent className="bg-black cursor-pointer max-w-[50vw]">
                <p>
                  Disclaimer: The holdings displayed may include both direct and
                  indirect investments through funds such as Exchange Traded
                  Funds (ETFs). The actual exposure to specific securities may
                  vary depending on the composition and management of these ETFs
                  within the portfolio.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="min-h-fit mt-4 w-full grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex flex-col justify-start items-start min-h-fit  w-full p-3 px-4 flex rounded-xl bg-[#35353538] col-span-1">
            <div className="flex justify-between items-center w-full mt-1">
              <text className="text-muted">
                Top 10 holdings as a percent of net asset
              </text>
              <div className="flex flex-row justify-end items-end">
                <text className="text-lg font-bold mr-1">
                  {Number(data?.perTopHolding * 100).toFixed(2)}
                </text>
                <text className="text-base text-gray-400">%</text>
              </div>
            </div>
            <div className="flex justify-between items-center w-full mt-3.5">
              <div className="w-full bg-[#58595b7d] h-2.5 rounded-full">
                <div
                  style={{
                    width: (data?.perTopHolding * 100 ?? 0) + "%",
                  }}
                  className=" bg-[#be9a2b] h-2.5 rounded-full"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start min-h-fit  w-full p-3 px-4 flex rounded-xl bg-[#35353538] col-span-1">
            <div className="flex justify-between items-center w-full mt-1">
              <text className="text-muted">
                Top 3 sectors as a percent of net asset
              </text>
              <div className="flex flex-row justify-end items-end ">
                <text className="text-lg font-bold mr-1">
                  {Number(data?.perSectorHoldings * 100).toFixed(2)}
                </text>
                <text className="text-base text-gray-400">%</text>
              </div>
            </div>
            <div className="flex justify-between items-center w-full mt-3.5">
              <div className="w-full bg-[#58595b7d] h-2.5 rounded-full">
                <div
                  style={{
                    width: (data?.perSectorHoldings * 100 ?? 0) + "%",
                  }}
                  className=" bg-[#be9a2b] h-2.5 rounded-full"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start min-h-fit  w-full p-3 px-4 flex rounded-xl bg-[#35353538] col-span-1">
            <text className="text-muted">Total Holdings</text>
            <div className="flex justify-between items-center w-full mt-2">
              <text className="text-2xl font-bold mr-1">{data?.count}</text>
            </div>
          </div>
        </div>
      </div>
      <div className="min-h-[300px] mt-5 w-full flex justify-start items-start overflow-x-auto overflow-y-hidden">
        <table className="tabel w-full">
          <thead
            style={{
              height: 50,
              borderBottom: "1px solid #58595b7d",
            }}
          >
            <tr className="pb-4">
              <th
                className={`${
                  getDeviceType() === "mobile" ? "min-w-[31px]" : "w-[3vw]"
                }`}
              ></th>
              <th
                className={`${
                  getDeviceType() === "mobile" ? "min-w-[150px]" : "w-[19vw]"
                }`}
              >
                Ticker
              </th>
              <th
                className={`${
                  getDeviceType() === "mobile" ? "min-w-[180px]" : "w-[30vw]"
                }`}
              >
                Name
              </th>
              <th
                className={`${
                  getDeviceType() === "mobile" ? "min-w-[180px]" : "w-[20vw]"
                }`}
              >
                Sector
              </th>
              <th className="w-[10vw] text-right px-3 ">Weight</th>
              {isIndex &&
                portSum?.portfolioName === "Global Multi Sector Index" && (
                  <th
                    className={`${
                      getDeviceType() === "mobile"
                        ? "min-w-[180px]"
                        : "w-[10vw]"
                    }  text-right px-3`}
                  >
                    Price
                  </th>
                )}
              {isIndex &&
                portSum?.portfolioName === "Global Multi Sector Index" && (
                  <th className="w-[10vw] text-right ">1D Change</th>
                )}

              <th className="w-[2vw] text-center"></th>
              <th
                className={`${
                  getDeviceType() === "mobile" ? "min-w-[100px]" : "w-[8vw]"
                } ml-2 pr-5 text-right`}
              >
                1M Chart
              </th>
            </tr>
          </thead>
          <TooltipProvider>
            {data?.holdings

              ?.sort((a, b) => b?.equity - a?.equity)
              ?.slice(0, showMore ? data?.holdings?.length : 10)
              ?.map((x, i) => {
                return (
                  <StockChart
                    key={i}
                    i={i}
                    x={x}
                    portSum={portSum}
                    total={data?.returnsTotal}
                    livePrice={liveTickerPrices[x?.symbol]}
                  />
                );
              })}
          </TooltipProvider>
        </table>
      </div>
      {isIndex && (
        <div
          onClick={() => {
            setshowMore(!showMore);
          }}
          className="w-full cursor-pointer h-[50px] bg-zinc-900 rounded-full flex items-center justify-center font-bold text-sm my-3 hover:bg-zinc-800"
        >
          {showMore ? "See Less" : "See More"}
        </div>
      )}

      {/* <div className="myblurbg">
        <VerificationForm
          reload={() => {
            setIsNewUser(!isNewUser);
          }}
        />
      </div> */}
    </div>
  );
}

const getTimestamp = (dateStr) => {
  const [day, month, year] = dateStr.split("-").map(Number);
  const date = new Date(year, month - 1, day);
  const timestamp = date.getTime();

  return timestamp;
};

function generateStraightLineData(points) {
  const x = [];
  const y = [];

  for (let i = 1; i <= points; i++) {
    x.push(i);
    y.push(2.5);
  }

  return { x, y };
}

const StockChart = ({ i, x, portSum, total, livePrice }) => {
  const { pathname } = useLocation();
  let isIndex = pathname === "/fund" ? false : true;
  const [liveChanges, setliveChanges] = useState(null);

  const [chartData, setChartData] = useState({
    x: [],
    y: [],
  });

  useEffect(() => {
    if (
      ((x?.type === "FI" || x?.type === "OTHERS") &&
        portSum?.currency?.code !== "USD") ||
      x?.region === "Ghana"
    ) {
      if (x?.name?.includes("Repo")) {
        setChartData(generateStraightLineData(30));
      } else {
        Api.getLocalSecurityChart(x?.securityID).then((resp) => {
          // console.log(resp?.benchmark[x?.securityID]);
          let x1 = resp?.data;
          if (x1) {
            setChartData({
              x: x1?.map((x) => getTimestamp(x?.marketQuoteTimestamp)),
              y: x1?.map((x) => x?.unitPrice),
            });
          }
        });
      }
    } else {
      Api.getStockChart(String(x?.symbol).replace("-", ".")).then((resp) => {
        if (resp?.status === "ok") {
          console.log(resp);
          setChartData({
            x: resp?.values?.map((x) => new Date(x?.datetime).getTime()),
            y: resp.values?.map((x) => Number(x?.close)),
          });
        }
      });
    }
  }, [portSum]);

  useEffect(() => {
    if (livePrice === undefined)
      setliveChanges({ change: 0, percentageChange: 0 });

    const change = livePrice?.price - livePrice?.prevClose;
    const percentageChange = (change / livePrice?.prevClose) * 100 ?? 0;

    setliveChanges({ change, percentageChange });
  }, [livePrice]);

  return (
    <tbody
      style={{
        border: "none",
        width: "100%",
      }}
      className=" text-white justify-center items-center w-full"
    >
      <tr
        style={{
          height: 60,
        }}
      >
        <td>{i + 1}</td>
        <td>
          <div className="flex items-center ">
            <div className="max-w-8 min-w-8 h-8 mr-1.5 bg-black rounded-lg">
              <img
                src={
                  x?.logoPath &&
                  (x?.region !== "United States of America (the)" ||
                    x?.type == "FI")
                    ? `https://blackstar-prod-public-milan.s3.eu-south-1.amazonaws.com/${x?.logoPath}`
                    : `https://cdn.profit.com/logo/stocks/${String(
                        x?.symbol
                      ).replace(".", "-")}.US.png`
                }
                className="w-full h-full rounded-lg object-cover"
              />
            </div>

            <text className="text-base font-bold">{x?.symbol}</text>
          </div>
        </td>
        <td>{x?.name}</td>

        <td>{x?.sector}</td>
        <td>
          <div className="flex items-end justify-end cursor-pointer ">
            {
              <text className="text-base mr-3 w-ful text-right">
                {isIndex &&
                portSum?.portfolioName === "Global Multi Sector Index" &&
                livePrice?.equity
                  ? livePrice?.equity
                  : Number(x?.equity * 100).toFixed(2) ?? "--"}
                %
              </text>
            }
          </div>
        </td>
        {isIndex &&
          portSum?.portfolioName === "Global Multi Sector Index" &&
          (chartData?.y?.length ? (
            <td className={`text-right px-3 `}>
              {livePrice?.currency ?? "$"}
              {Number(livePrice?.price ?? chartData?.y[0]).toFixed(2)}
            </td>
          ) : (
            <div className="flex gap-2 h-16 items-center mr-2">
              <div className="w-14 rounded h-5 bg-zinc-900 animate-pulse" />
            </div>
          ))}
        {isIndex &&
          portSum?.portfolioName === "Global Multi Sector Index" &&
          (isWeekend(now) || isUSHoliday(now) ? (
            <td>
              <div className="flex items-center cursor-pointer flex justify-end items-end">
                <span
                  className={`w-[65px] mr-3 text-right ${
                    livePrice?.price - chartData?.y[0] > 0
                      ? "text-[#00c441]"
                      : "text-[#ff0007]"
                  }`}
                >
                  {livePrice?.price - chartData?.y[0] > 0 ? "+" : "-"}
                  {livePrice?.currency}
                  {Math.abs(livePrice?.price - chartData?.y[0]).toFixed(2)}
                </span>
                <div
                  className={`${
                    Number(
                      percentageDifference(
                        Number(livePrice?.price),
                        Number(chartData?.y[0])
                      )
                    ).toFixed(2) > 0
                      ? "bg-green-600"
                      : "bg-red-700"
                  }
              
                 p-1.5 rounded-lg`}
                >
                  {Math.abs(
                    percentageDifference(
                      Number(livePrice?.price),
                      Number(chartData?.y[0])
                    )
                  ).toFixed(2)}
                  %
                </div>
              </div>
            </td>
          ) : (
            <td>
              {chartData?.y?.length ? (
                <div className="flex items-center cursor-pointer flex justify-end items-end">
                  <span
                    className={`w-[65px] mr-3 text-right ${
                      Number(
                        chartData?.y[0] -
                          Number(livePrice?.price ?? chartData?.y[1])
                      ) > 0
                        ? "text-[#00c441]"
                        : "text-[#ff0007]"
                    }`}
                  >
                    {Number(
                      chartData?.y[0] -
                        Number(livePrice?.price ?? chartData?.y[1])
                    ) > 0
                      ? "+"
                      : "-"}
                    {livePrice?.currency}
                    {Math.abs(
                      Number(
                        chartData?.y[0] -
                          Number(livePrice?.price ?? chartData?.y[1])
                      )
                    ).toFixed(2)}
                  </span>
                  <div
                    className={`${
                      Number(
                        chartData?.y[0] -
                          Number(livePrice?.price ?? chartData?.y[1])
                      ) > 0
                        ? "bg-green-600"
                        : "bg-red-700"
                    }
                
                   p-1.5 rounded-lg`}
                  >
                    {Math.abs(
                      percentageDifference(
                        Number(livePrice?.price ?? chartData?.y[1]),
                        Number(chartData?.y[0])
                      )
                    ).toFixed(2)}
                    %
                  </div>
                </div>
              ) : (
                <div className="flex gap-2">
                  <div className="w-14 rounded h-5 bg-zinc-900 animate-pulse" />
                  <div className="w-14 rounded h-5 bg-zinc-900 animate-pulse" />
                </div>
              )}
            </td>
          ))}

        <td />
        <td
          className={`${
            getDeviceType() === "mobile" ? "min-w-[100px]" : "w-[50px]"
          } ml-2 text-right`}
        >
          {x?.type === "FI" || x?.type === "OTHERS" || x?.region === "Ghana" ? (
            <SparklineCompLocal data={chartData} price={chartData?.y[0]} />
          ) : (
            <SparklineComp data={chartData} price={livePrice} />
          )}
        </td>
      </tr>
    </tbody>
  );
};

export function VerificationForm({ reload }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [loading, setloading] = useState(false);

  const handleSendOtp = async (e) => {
    e?.preventDefault();
    try {
      setloading(true);
      const response = await fetch(`https://${DOMAIN}/user/send-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email }),
      });
      const result = await response.json();
      if (response.ok) {
        setIsOtpSent(true);
        setloading(false);
        // alert(result.message);
      } else {
        setloading(false);
        alert(result.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleVerifyOtp = async (code) => {
    // console.log("request");
    try {
      const response = await fetch(`https://${DOMAIN}/user/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp: code }),
      });
      const result = await response.json();
      if (response.ok) {
        setloading(true);
        setIsOtpVerified(true);
        localStorage.setItem("isUser", true);
        setloading(false);
        reload();
        // alert(result.message);
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <div
      className="max-w-md mx-auto p-4 bg-black mt-10 shadow-lg rounded-lg"
      style={{
        borderWidth: 1,
        borderColor: "#eeeeee20",
      }}
    >
      {!isOtpSent ? (
        <div onSubmit={handleSendOtp} className="space-y-4">
          <text>Register your email to continue</text>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-500"
            >
              Name
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-[#be9a2b] focus:ring-bg-[#be9a2b] sm:text-sm h-[40px] bg-transparent px-2"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-500"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-[#be9a2b] focus:ring-bg-[#be9a2b] sm:text-sm h-[40px] bg-transparent px-2"
            />
          </div>
          <button
            onClick={handleSendOtp}
            type="submit"
            className="w-full bg-[#be9a2b] text-black py-2 px-4 rounded-md hover:bg-[#be9a2b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#be9a2b]"
          >
            {loading ? "Loading" : "Continue"}
          </button>
          <div className="w-full flex justify-center items-center">
            <text className="text-center align-center w-full">
              or register with
            </text>
          </div>
          <div className="flex justify-between gap-2">
            {/* <button
              onClick={() => {}}
              type="submit"
              className="w-full flex justify-center items-center gap-2 bg-[#be9a2b] text-black py-2 px-4 rounded-md hover:bg-[#be9a2b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#be9a2b]"
            >
              <img src="/logo-microsoft.svg" className="w-5" />
              Microsoft
            </button> */}
            <button
              onClick={(e) => {
                e.preventDefault();
                window.location.href =
                  "https://webapi.blackstargroup.ai/user/auth/google";
              }}
              type="submit"
              className="w-full flex justify-center items-center gap-2 border text-white py-2 px-4 rounded-md hover:bg-[#be9a2b10] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#be9a2b]"
            >
              <img src="/logo-google.svg" className="w-5" />
              Google
            </button>
          </div>
        </div>
      ) : !isOtpVerified ? (
        <div>
          {/* <text>We have sent a 6 digit code to you email</text> */}
          <div>
            <label
              htmlFor="otp"
              className="block text-sm font-medium text-gray-500 text-center"
            >
              One Time Password
            </label>
            <div className="w-full flex flex-col justify-center items-center my-4">
              <OTPInput
                value={otp}
                onChange={async (x) => {
                  setOtp(x);
                  if (x.length == 6) {
                    await handleVerifyOtp(x);
                  }
                }}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={loading}
                inputClassName={
                  "ipx border-inherit rounded scale-125 bg-black border"
                }
                className={"-mr-5 text-white"}
              />
              <text className="text-zinc-200 text-center text-xs w-[80%] mt-3 mb-2">
                A message with a verification code has been sent to your email.
                Enter the code to continue{" "}
              </text>
              <text
                onClick={() => {
                  handleSendOtp();
                }}
                className="text-white cursor-pointer hover:text-[#be9a2b] text-sm"
              >
                Resend OTP
              </text>

              {loading && <Loader className="animate-spin mt-2" />}
            </div>
          </div>
          {/* <button
            type="submit"
            className="w-full bg-[#be9a2b] text-white py-2 px-4 rounded-md hover:bg-[#be9a2b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#be9a2b]"
          >
            Verify OTP
          </button> */}
        </div>
      ) : (
        <div className="text-center text-green-600">
          OTP Verified Successfully!
        </div>
      )}
    </div>
  );
}
