import React from "react";
import "../NestedAccordionWithTabs/nestedaccordionwithtabs.scss";

const Nestedaccordionwithtabs = ({ nestedData }) => {
  // console.log("nested", nestedData.id);
  return (
    <React.Fragment>
      <div className="nestedaccordion-main">
        <div className="accordion" id={`accordion-${nestedData.id}`}>
          {nestedData?.tabcontent?.map((accordionItem, index) => {
            return (
              <div className="accordion-item" key={index}>
                <div
                  className="main_acc-heading"
                  id={`heading-${accordionItem.id}`}
                >
                  <h3>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${accordionItem.id}`}
                      aria-expanded="false"
                      aria-controls={`collapse-${accordionItem.id}`}
                    >
                      {accordionItem.companyName}
                    </button>
                  </h3>
                </div>
                <div
                  id={`collapse-${accordionItem.id}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading-${accordionItem.id}`}
                  data-bs-parent={`#accordion-${nestedData.id}`}
                >
                  <div className="main_acc-content">
                    <div
                      className="accordion-1"
                      id={`accordion-${accordionItem.id}`}
                    >
                      {accordionItem?.companyData?.map((company, i) => {
                        return (
                          <div className="inner_acc-wrapper" key={i}>
                            <div className="inner_acc-heading" id="heading-1-1">
                              <h3>
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse-${company.id}`}
                                  aria-expanded="true"
                                  aria-controls={`collapse-${company.id}`}
                                >
                                  {company.companySubtitle}
                                </button>
                              </h3>
                            </div>
                            <div
                              id={`collapse-${company.id}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="heading-1-1"
                              data-bs-parent={`#accordion-${accordionItem.id}`}
                            >
                              <div className="pdf-wrapper">
                                {company?.downloadpdf?.map((pdf, i) => {
                                  return (
                                    <div className="links-pdf" key={i}>
                                      <a
                                        href={pdf.link}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        // download="pdf-1"
                                        className="pdflink"
                                      >
                                        <i className="fa-solid fa-download"></i>
                                        {pdf?.pdfName}
                                      </a>
                                    </div>
                                  );
                                })}

                                {/* <div id="accordion-1-1">
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="heading-1-1-1"
                                    >
                                      <h3>
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapse-1-1-1"
                                          aria-expanded="false"
                                          aria-controls="collapse-1-1-1"
                                        >
                                          Hello3
                                        </button>
                                      </h3>
                                    </div>
                                    <div
                                      id="collapse-1-1-1"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="heading-1-1-1"
                                      data-bs-parent="#accordion-1-1"
                                    ></div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Nestedaccordionwithtabs;
