// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Icon/rightarrow1.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subtitle {
  color: #58595B;
  text-decoration: none;
  text-align: left;
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}
.subtitle:hover {
  color: #ffffff;
}
.subtitle:hover::before {
  opacity: 1;
}
.subtitle::before {
  content: "";
  position: absolute;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  left: 0;
  background-size: contain;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0;
}

.active {
  color: #ffffff;
}
.active::before {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/SubCatDetails/subcatdetails.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,qBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;AACJ;AACI;EACI,cAAA;AACR;AACQ;EACI,UAAA;AACZ;AAGI;EACI,WAAA;EACA,kBAAA;EACA,yDAAA;EACA,WAAA;EACA,YAAA;EACA,4BAAA;EACA,OAAA;EACA,wBAAA;EACA,QAAA;EACA,6BAAA;EACA,UAAA;AADR;;AAOA;EACI,cAAA;AAJJ;AAMI;EACI,UAAA;AAJR","sourcesContent":[".subtitle {\n    color: #58595B;\n    text-decoration: none;\n    text-align: left;\n    display: block;\n    position: relative;\n    padding-left: 20px;\n    cursor: pointer;\n\n    &:hover {\n        color: #ffffff;\n\n        &::before {\n            opacity: 1;\n        }\n    }\n\n    &::before {\n        content: \"\";\n        position: absolute;\n        background-image: url(\"../../Assets//Icon//rightarrow1.svg\");\n        width: 14px;\n        height: 14px;\n        background-repeat: no-repeat;\n        left: 0;\n        background-size: contain;\n        top: 50%;\n        transform: translate(0, -50%);\n        opacity: 0;\n    }\n\n\n}\n\n.active {\n    color: #ffffff;\n\n    &::before {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
