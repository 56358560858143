import React, { useState, useEffect } from "react";
import { XAxis, YAxis, Area, AreaChart, ReferenceLine } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../ui/chart";

const TradingHoursChart = ({ dataChart, prevlivePrice }) => {
  const [data, setData] = useState([]);
  const [yDomain, setYDomain] = useState([0, 0]);

  useEffect(() => {
    if (dataChart?.length) {
      setData(
        dataChart?.filter((x) => {
          const date = new Date(x?.timestamp);
          const hours = date.getHours();

          return hours >= 4 && hours < 20;
        })
      );

      // Calculate min and max prices with a 5% buffer
      const prices = dataChart.map((item) => parseFloat(item.price));
      const minPrice = Math.min(...prices);
      const maxPrice = Math.max(...prices);
      const priceRange = maxPrice - minPrice;
      const buffer = priceRange * 0.15; // buffer

      setYDomain([
        Math.max(0, Math.min(minPrice, prevlivePrice) - buffer), // Ensure we don't go below 0
        Math.min(maxPrice, prevlivePrice) + buffer,
      ]);
    }
  }, [dataChart, prevlivePrice]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const getStatus = (time) => {
    const hour = time.getHours();
    const minute = time.getMinutes();
    if (hour < 9 || (hour === 9 && minute < 30)) return "";
    if (hour < 16 || (hour === 16 && minute === 0)) return "";
    return "";
  };

  const customTickFormatter = (timestamp) => {
    const time = new Date(timestamp);
    const formattedTime = formatTimestamp(timestamp);
    const status = getStatus(time);
    return `${formattedTime} ${status === "regular" ? "" : ``}`;
  };

  const [xAxisTicks, setXAxisTicks] = useState([]);

  const generate30MinuteTicks = (start, end) => {
    const cd = new Date(dataChart[0]?.timestamp); // Get the date from the timestamp
    const ticks = [];

    for (
      let time = new Date(start);
      time <= new Date(end);
      time.setMinutes(time.getMinutes() + 30)
    ) {
      const tickTime = new Date(cd); // Start with the date from `cd`
      tickTime.setHours(time.getHours(), time.getMinutes(), 0, 0); // Set the time to the current time iteration

      ticks.push(tickTime.getTime());
    }

    return ticks;
  };

  useEffect(() => {
    const preMarketStartTime = new Date().setHours(4, 0, 0, 0);
    const postMarketEndTime = new Date().setHours(20, 0, 0, 0);
    const marketOpenTime = new Date().setHours(9, 30, 0, 0);

    const isMarketOpen =
      (new Date().getHours() > 13 ||
        (new Date().getHours() === 13 && new Date().getMinutes() >= 30)) &&
      new Date().getHours() < 22;

    setXAxisTicks(
      generate30MinuteTicks(
        isMarketOpen ? marketOpenTime : preMarketStartTime,
        postMarketEndTime
      )
    );
  }, []);

  const [chartColor, setchartColor] = useState("#dc2626");

  useEffect(() => {
    let chartColor_ =
      prevlivePrice > data[data?.length - 1]?.price ? "#dc2626" : "#16a34a";

    setchartColor(chartColor_);
  }, [data]);

  const chartConfig = {
    value: {
      label: "Price: ",
      color: chartColor,
    },
  };

  const PulsingDot = ({ cx, cy }) => {
    return (
      <g>
        <circle
          cx={cx}
          cy={cy}
          r={5}
          fill={chartColor}
          className="animate-pulse"
        />
      </g>
    );
  };

  const [pulse, setpulse] = useState(false);
  useEffect(() => {
    setInterval(() => {
      setpulse(true);
      setTimeout(() => {
        setpulse(false);
      }, 5000);
    }, 60000);
  }, []);

  const CustomDot = (props) => {
    const { cx, cy, payload, value } = props;
    const isLast = payload?.timestamp === data[data.length - 1]?.timestamp;

    if (isLast && pulse) {
      return <PulsingDot cx={cx} cy={cy} chartColor={chartColor} />;
    }
    return null;
  };

  return (
    <div className="w-full h-[150px] md:h-[300px] ml-1">
      <ChartContainer className=" h-full w-full " config={chartConfig}>
        <AreaChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <defs>
            <linearGradient id="fillMobile" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={chartColor} stopOpacity={0.3} />
              <stop offset="99%" stopColor="transparent" stopOpacity={0.4} />
            </linearGradient>
          </defs>

          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            dataKey="timestamp"
            type="number"
            domain={["dataMin", "dataMax"]}
            tickFormatter={customTickFormatter}
            scale="time"
            ticks={xAxisTicks}
            tickLine={false}
            axisLine={false}
            interval="preserveStartEnd"
          />
          <YAxis
            // hide={true}
            tickLine={false}
            axisLine={false}
            domain={yDomain}
            tickFormatter={(value) => `$${value.toFixed(4)}`}
          />
          <ChartTooltip
            cursor={false}
            formatter={customTickFormatter}
            content={
              <ChartTooltipContent
                myFormatter={customTickFormatter}
                custom={true}
                dp={4}
                className="bg-black p-2"
              />
            }
          />

          <ReferenceLine
            y={prevlivePrice}
            stroke="gray"
            label={{
              value: `Previous Close ($${prevlivePrice})`,
              position: "right", // Position at the end of the line
              dy: -10,
              dx: -140, // Adjust vertical position to place text above the line
              fill: "gray", // Text color
            }}
            strokeDasharray="3 3"
            isFront={false}
          />

          {/* Pre-market (gray) */}
          {/* {getMarketStatus(true)?.stat === "Pre-Market" && ( */}
          {/* <Area
            type="monotone"
            dataKey="price"
            stroke={chartColor}
            dot={false}
            name="Post-market"
            strokeWidth={2}
            connectNulls
            animateNewValues={true}
            isAnimationActive={false}
            fill="url(#fillMobile)"
            data={data.filter((d) => d.timestamp < marketOpenTime)}
          /> */}
          {/* // )} */}

          {/* Regular market (green) */}
          {/* {getMarketStatus(true)?.stat === "Market Open" && ( */}
          <Area
            type="monotone"
            dataKey="price"
            stroke={chartColor}
            // dot={false}
            name="Post-market"
            strokeWidth={2}
            connectNulls
            animateNewValues={true}
            isAnimationActive={false}
            fill="url(#fillMobile)"
            // data={smoothDataArray(data, 0.1)}
            dot={(props) => <CustomDot {...props} dataLength={data.length} />}
            className="transition-all"
          />
          {/* // )} */}

          {/* .filter(
              (d) =>
                d.timestamp >= marketOpenTime && d.timestamp <= marketCloseTime
            ) */}

          {/* Post-market (gray) */}
          {/* {getMarketStatus(true)?.stat === "Market Closed" && ( */}
          {/* <Area
            type="monotone"
            dataKey="price"
            stroke={chartColor}
            dot={false}
            name="Post-market"
            strokeWidth={2}
            connectNulls
            animateNewValues={true}
            isAnimationActive={false}
            fill="url(#fillMobile)"
            data={data.filter((d) => d.timestamp > marketCloseTime)}
          /> */}
          {/* // )} */}
        </AreaChart>
      </ChartContainer>
    </div>
  );
};

export default TradingHoursChart;
