import React, { useEffect, useState } from "react";
import "../Products/products.scss";
import data from "../../../data";
import { useLocation } from "react-router-dom";

const Products = (props) => {
  const params = useLocation();
  const [animationClass, setAnimationClass] = useState("fade");
  useEffect(() => {
    if (animationClass === "fade") {
      setAnimationClass("fade2");
    } else {
      setAnimationClass("fade");
    }
  }, [params.pathname]);
  return (
    <React.Fragment>
      <div className="section-product">
        {data
          ?.filter((item) => item.title === props.props.title)
          .map((value) =>
            value.product?.map((values, id) => {
              return (
                <div
                  className={`${animationClass === "fade" ? "fade2" : "fade"}`}
                >
                  {props.props.title === "GNII" ? (
                    <div className={`accordionGnni`} id="accordionExample">
                      {values?.productGnii.map((gnii, i) => {
                        return (
                          <div className="accordion-item" key={id}>
                            <h2 className="accordion-header" id="headingOne">
                              <img src={gnii.img} className="gnniIcon"></img>
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${gnii.id}`}
                                aria-expanded="true"
                                aria-controls={`collapse${gnii.id}`}
                              >
                                <span className="acc-title">{gnii.title}</span>
                              </button>
                            </h2>
                            <div
                              id={`collapse${gnii.id}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                {gnii?.innerdata.map((bullets, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <p>{bullets.bullet1}</p>
                                      <p>{bullets.bullet2}</p>
                                      <p>
                                        Write us at :
                                        <a
                                          href={bullets.link}
                                          className="highlight-link"
                                        >
                                          {bullets.link}
                                        </a>
                                      </p>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className={`product-heading`}>
                      <h2>{values.title}</h2>
                    </div>
                  )}
                </div>
              );
            })
          )}
      </div>
    </React.Fragment>
  );
};

export default Products;
